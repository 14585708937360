import React from 'react'
import { Box, Text, Card, Link } from 'theme-ui'
import { Stack, Main } from '@layout'
import PageTitle from '@components/PageTitle'
import Divider from '@components/Divider'
import Seo from '@widgets/Seo'

const styles = {
  column: {
    flex: `auto`,
    flexBasis: [`full`, `full`, `1/2`],
  },
  link: {
    fontWeight: '700',
  },
}

export default () => (
  <>
    <Seo title='Hubungi Saya' />
    <Divider />
    <Stack>
      <Main>
        <PageTitle header='Hubungi Saya' />
        <Divider />
        <Box sx={styles.column}>
          <Card variant='paper'>
            <Text variant='p'>
              Ada yang bisa saya bantu? Tinggalkan pesan melalui{' '}
              <Link sx={styles.link} href='https://twitter.com/husniadil'>
                Twitter
              </Link>{' '}
              atau{' '}
              <Link
                sx={styles.link}
                href='https://www.linkedin.com/in/husniadil'
              >
                LinkedIn
              </Link>
              .
            </Text>
          </Card>
        </Box>
      </Main>
    </Stack>
  </>
)
